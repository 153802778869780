import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['quizChoose1', 'quizChoose2', 'quizChoose3', 'quizChoose4'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const Quiz = makeShortcode("Quiz");
const TextCustom = makeShortcode("TextCustom");
const BasicAudio = makeShortcode("BasicAudio");
const ButtonDialog = makeShortcode("ButtonDialog");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "you-and-your-teenagers-friends"
    }}>{`You and your teenager’s friends`}</h1>
    <p>{`An important part of helping your teenager develop supportive relationships is by being involved in that process. `}<em parentName="p">{`But how?
Should I:`}</em></p>
    <Paper mdxType="Paper">
  <Quiz id="quizChoose1" once={false} question="Choose my teenager’s friends for them?" answers={[{
        name: 'Yes',
        correct: false,
        response: <div>
            <p>
              <b>You answered: Yes, I should choose my teenager’s friends</b>
            </p>
            <p>
              This could backfire and jeopardise your relationship with your teenager! It would also be impossible to
              keep track of every person they spend time with. A better way to influence their choice of friends is to
              talk with them about qualities that really count in a friend, such as being kind and trustworthy, rather
              than popular and “cool”. Having understanding friends is an important key to handling peer pressure.
            </p>
          </div>
      }, {
        name: 'No',
        correct: true,
        response: <div>
            <p>
              <b>You answered: No, I should not choose my teenager’s friends</b>
            </p>
            <p>
              You have the right approach in letting your teenager form their own friendships. Of course you are still
              welcome to provide guidance by talking with your teenager about qualities that really count in a friend,
              such as being kind and trustworthy, rather than popular and “cool”. Having understanding friends is an
              important key to handling peer pressure.
            </p>
          </div>
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizChoose2" once={false} question="Tell my teenager if I disapprove of their friends?" answers={[{
        name: 'Yes',
        correct: false,
        response: <div>
            <p>
              <b>You answered: Yes, I should tell my teenager if I disapprove of their friends</b>
            </p>
            <p>
              It is best if you like your teenager’s friends, however, this may not always be possible. But
              communicating your dislike for your teenager’s friends probably won’t change anything. Your teenager may
              interpret your disapproval of their friends as disapproval of themselves. This could have a negative
              impact on your relationship with them, and they may even continue the friendship behind your back. A
              better alternative is to ask your teenager why they are friends with the friends you don’t approve of, and
              to talk about qualities that are good to look for in friends.
            </p>
          </div>
      }, {
        name: 'No',
        correct: true,
        response: <div>
            <p>
              <b>You answered: No, I should not tell my teenager if I disapprove of their friends</b>
            </p>
            <p>
              You are right in choosing not to communicate disapproval of your teenager’s friends. A better alternative
              is to ask your teenager why they are friends with the friends you don’t approve of, and to talk about
              qualities that are good to look for in friends.
            </p>
          </div>
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizChoose3" once={false} question="Encourage my teenager to invite their friends over?" answers={[{
        name: 'Yes',
        correct: true,
        response: <div>
            <p>
              <b>You answered: Yes, I should encourage my teenager to invite their friends over</b>
            </p>
            <p>
              Absolutely! This is a great way to get to know your teenager’s friends in a safe and controlled
              environment. It will also give you an idea of the types of activities your teenager enjoys doing with
              their friends. Ask your teenager to invite some friends over. They’ll most likely want to be left to
              themselves, but there’ll still be opportunities to gain an awareness of what kinds of people their friends
              are.
            </p>
          </div>
      }, {
        name: 'No',
        correct: false,
        response: <div>
            <p>
              <b>You answered: No, I should not encourage my teenager to invite their friends over</b>
            </p>
            <p>
              Unless your home circumstances are unsuitable, it is strongly recommended that you encourage your teenager
              to invite friends over, even if it’s only for a few hours. This is a really good way to learn about who
              your teenager is spending time with.
            </p>
          </div>
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizChoose4" once={false} question="Interact with my teenager’s friends?" answers={[{
        name: 'Yes',
        correct: true,
        response: <div>
            <p>
              <b>You answered: Yes, I should interact with my teenager’s friends</b>
            </p>
            <p>
              Your interaction should be at a level that’s appropriate. Your teenager is becoming an adult and they need
              space and freedom to do this. Good examples of appropriate interaction include sharing a meal together,
              taking them to the movies (but not watching the movie with them), or going surfing with them (if you’re
              able and your teenager is keen). Talking to their friends is fine, as long as it isn’t about personal
              issues and you don’t keep them talking for hours! Parents shouldn’t try to be friends with their
              teenager’s friends either – an important part of teenage friendships is about being independent from
              parents.
            </p>
          </div>
      }, {
        name: 'No',
        correct: false,
        response: <div>
            <p>
              <b>You answered: No, I should not interact with my teenager’s friends</b>
            </p>
            <p>
              An appropriate level of interaction between yourself and your teenager’s friends is recommended. If you
              interpreted interacting with your teenager’s friends as hanging out with them, then you were right to
              answer 'No' to this question! Appropriate interaction with their friends during the teenager years
              typically involves just talking to them – not about personal topics or for hours, but just basic
              conversation so that you know a little about them and they feel comfortable around you.
            </p>
          </div>
      }]} mdxType="Quiz" />
    </Paper>
    <br />
    <TextCustom customColor="blue" mdxType="TextCustom">
  <i>How do you feel when your mum or dad talks to your friends?</i> Hear what Charlotte, 15 years, says…
    </TextCustom>
    <BasicAudio src="/audios/module_6_audio_clip.mp3" mdxType="BasicAudio" />
    <ButtonDialog linkText="Read Transcript" title="How do you feel when your Mum or Dad talks to your friends?" mdxType="ButtonDialog">
  <p>Charlotte, 15 years, says…</p>
  <p>
    I feel embarrassed when my parents talk to my friends. I’d prefer it if they kept it simple and said ‘hi’ and
    nothing more.
  </p>
  <p>
    If they <i>must</i> talk to my friends, they shouldn’t tell embarrassing jokes, they shouldn’t be so enthusiastic,
    and I <i>really</i> don’t want them asking my friends about how school is going.
  </p>
  <p>
    The other thing I <i>hate</i> is my parents talking to my friends about me! <i>Don’t </i>
    ask my friends <i>any</i> questions about me!
  </p>
  <p>
    The other <i>really</i> embarrassing topic is dating – NEVER ask my friends if they're seeing someone.
  </p>
  <p>My parents should stick with topics like ‘What sport do you play on the weekend?’.</p>
    </ButtonDialog>
    <SingleImage smallGridSize={8} gridSize={8} src="/images/shared/Oriental_Mo_friends.svg" alt="Mother with teenager and their friends" mdxType="SingleImage" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      